<template>
  <div>
    <h1>Criar Romaneio</h1>
    
    <v-btn @click="$router.back()" class="ma-2" color="error">
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>
    
    <v-form @submit.prevent="save">
      <div class="d-flex flex-wrap">
        <div class="delivery-date-select">
          <v-date-picker
              locale="pt"
              full-width
              v-model="delivery.date"
              class="elevation-2"
          ></v-date-picker>
        </div>
        <div class="driver-select">
          <v-progress-circular v-if="drivers.length < 1" indeterminate class="ma-2" />
          <template v-if="drivers.length > 0">
            <v-container
              class="px-0"
              fluid
            >
              <v-radio-group v-model="delivery.driver_id">
                <v-radio
                  v-for="driver in drivers"
                  :key="driver.id"
                  :label="`${driver.name}`"
                  :value="driver.id"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </template>
        </div>
      </div>
      <v-btn
        color="success"
        block
        class="my-4"
        type="submit"
        :loading="saving"
      >Salvar</v-btn>
    </v-form>
  </div>
</template>

<script>

import http from '@/modules/http';

export default {
  mounted() {
    this.getDrivers();
  },
  data() {
    return {
      saving: false,
      drivers: [],
      delivery: {
        date: new Date().toISOString().slice(0,10),
        driver_id: '',
      }
    }
  },
  methods: {
    getDrivers() {
      http.get('api/drivers')
      .then(res => {
        this.drivers = res.data;
      });
    },
    save() {
      if(this.delivery.driver_id) {
        this.saving = true;

        http.post('api/deliveries', this.delivery)
          .then(({data}) => {
            this.saving = false;
            this.$router.push(`/editar-romaneio/${data.id}`);
          }).catch(err => {
            this.saving = false;
            console.log(err);

            this.$side({
              type: 'error',
              msg: 'Erro ao salvar romaneio'
            });
          });
      } else {
        this.$side({
          type: 'error',
          msg: 'Escolha um motorista!'
        });
      }
    }
  },
}
</script>

<style scoped>
  .delivery-date-select {
    width: 50%;
  }
  .driver-select {
    overflow-x: auto;
    height: 375px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 2px 5px;
    padding: 0 20px;
    width: 320px;
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    .delivery-date-select {
      width: 100%;
    }
  }
</style>